<template>
    <div class="register-wrap">
      <div class="container">
        <div class="lable">{{ $t('login.account_registration') }}</div>
        <!-- 账号注册 -->
        <div class="detail">
          <a-form
            ref="formRef"
            :model="formState"
            :rules="rules"
            size="large"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 13 }"
            v-if="!successRegister"
          >
          <!-- 单位 -->
            <a-form-item
              :label="$t('login.enterprise')"
              name="enterpriseId"
              v-if="useEnterprise == 1 || useUnitRegister == 1"
            >
              <a-select
                v-model:value="formState.enterpriseId"
                :options="enterpriseList"
                show-search
                :placeholder="$t('CM_Select')"
                size="large"
                :filterOption="filterOption"
              >
              <!-- 请选择 -->
              </a-select>
            </a-form-item>
            <a-form-item
              class="a-form-item-tip"
              v-if="useEnterprise == 1 || useUnitRegister == 1"
              :wrapper-col="{ span: 13, offset: 6 }"
            >
              <div class="tip">
                <span>{{ $t('login.enterprise_tip', 1) }}</span>
                <!-- 如果单位列表中找不到所在单位，请联系单位负责人 -->
                <span v-if="useUnitRegister == 1">
                  {{ $t('login.enterprise_tip', 2) }}
                  <!-- 先在平台进行 -->
                  <router-link to="/register/company">&nbsp;{{ $t('login.enterprise_registration') }}</router-link>
                  <!-- 单位注册 -->
                </span>
              </div>
            </a-form-item>
            <!-- 账号 -->
            <a-form-item :label="$t('login.账号')" name="account">
              <a-input
                v-model:value="formState.account"
                :placeholder="$t('login.account_regist')"
                size="large"
              />
              <!-- 请输入至少三个字符 -->
            </a-form-item>
            <!-- 密码 -->
            <a-form-item :label="$t('login.password')" name="password">
              <a-input-password
                v-model:value="formState.password"
                type="password"
                :placeholder="$t('LB_QA_PleaseEnter')"
                size="large"
              />
              <!-- 请输入 -->
            </a-form-item>
            <!-- 姓名 -->
            <a-form-item v-if="useName" :label="$t('login.name')" name="realName">
              <a-input
                v-model:value="formState.realName"
                :placeholder="$t('LB_QA_PleaseEnter')"
                size="large"
              />
              <!-- 请输入 -->
            </a-form-item>
            <!-- 手机号 -->
            <a-form-item :label="$t('login.mobile')" name="mobile">
              <a-input
                v-model:value="formState.mobile"
                :disabled="disabled"
                :placeholder="$t('LB_QA_PleaseEnter')"
                size="large"
              />
              <!-- 请输入 -->
            </a-form-item>
            <!-- 验证码 -->
            <a-form-item :label="$t('login.code')" name="code">
              <a-input-search
                v-model:value="formState.code"
                size="large"
                :placeholder="$t('LB_QA_PleaseEnter')"
                @search="getCode(formState.mobile)"
              >
              <!-- 请输入 -->
                <template #enterButton>
                  <a-button
                    type="primary"
                    style="background: #faad14; border-color: #faad14"
                    :disabled="disabled"
                    >{{ codeText }}</a-button
                  >
                </template>
              </a-input-search>
            </a-form-item>
            <!-- 邮箱 -->
            <a-form-item :label="$t('login.email')" name="email">
              <a-input
                v-model:value="formState.email"
                :placeholder="$t('LB_QA_PleaseEnter')"
                size="large"
              />
              <!-- 请输入 -->
            </a-form-item>
            <!-- 城市 -->
            <a-form-item v-if="useArea" :label="$t('login.area')" name="city">
              <a-cascader
                :changeOnSelect="true"
                allowClear
                :field-names="{
                  label: 'name',
                  value: 'name',
                  children: 'list',
                }"
                v-model:value="formState.city"
                :placeholder="$t('CM_Select')"
                :options="areaTree"
              />
              <!-- 请选择 -->
            </a-form-item>
            
            <!-- 岗位 -->
            <a-form-item v-if="formSetting.filter(item=>item.lable=='position').length>0" :label="$t('login.address')" name="address">
              <a-select
                  :getPopupContainer="triggerNode => triggerNode.parentNode"
                  v-model:value="addFrom[item.key]"
                  allowClear
                  :placeholder="`${$t('common.请选择')}${item.name}`"
                >
                  <template v-if="item.key == 'position'">
                    <a-select-option
                      v-for="opt in positionList"
                      :key="opt.positionId"
                      :value="opt.positionId"
                    >
                      {{ opt.positionName }}
                    </a-select-option>
                  </template>
                  <template v-else>
                    <a-select-option
                      v-for="opt in forOption[item.key]"
                      :value="opt.id"
                      :key="opt.id"
                    >
                      {{ opt.name }}
                    </a-select-option>
                  </template>
              </a-select>
            </a-form-item>
  
  
            <!-- 地址 -->
            <a-form-item v-if="useAddress" :label="$t('login.address')" name="address">
              <a-input
                v-model:value="formState.address"
                :placeholder="$t('LB_QA_PleaseEnter')"
                size="large"
              />
              <!-- 请输入 -->
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 14, offset: 9 }">
              <span class="btn"
                ><a-button
                  type="primary"
                  block
                  @click.prevent="onSubmit"
                  size="large"
                  :loading="submitLoading"
                  >{{ $t('login.register_btn') }}</a-button
                ></span
              >
              <!-- 注册 -->
              <span class="btn"
                ><a-button
                  @click.prevent="toLogin"
                  block
                  size="large"
                  style="margin-left: 20px"
                  >{{ $t('login.to_login') }}</a-button
                ></span
              >
              <!-- 去登录 -->
            </a-form-item>
          </a-form>
          <!-- 恭喜您，注册成功！ -->
          <a-result
            status="success"
            :title="$t('login.register_success_title')"
            :sub-title="$t('login.register_success_tip')"
            v-else
          >
          <!-- 您的账号已经注册成功，快去登录平台试试吧。 -->
            <template #extra>
              <a-button key="console" type="primary" @click="toLogin"
                >{{ $t('login.to_login') }}</a-button
              >
              <!-- 去登录 -->
            </template>
          </a-result>
        </div>
      </div>
      <div class="copyright">
        <span v-if="studyCopyright" style="padding: 0 5px">{{
          studyCopyright
        }}</span>
        <a
          :href="`https://beian.miit.gov.cn${
            newWindow == 1 ? '?ddtab=true' : ''
          }`"
          :target="newWindow == 1 ? '_blank' : '_self'"
          v-if="icpNo"
        >
          {{ icpNo }}
        </a>
        <a
          v-if="ncNo"
          :target="newWindow == 1 ? '_blank' : '_self'"
          :href="`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${ncNo}${
            newWindow == 1 ? '&ddtab=true' : ''
          }`"
        >
          公网安备 {{ ncNo }}号
        </a>
      </div>
    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n';
  import { reactive, ref, watch, toRefs } from "vue";
  import { useStore } from "vuex";
  import {
    validatorMobile,
    validatorEmail,
    validatorPassword,
    validatorAccount,
  } from "@/utils/formRules";
  import {
    searchEnterprise,
    sendCode,
    registerIndividual,
    getArea,
  } from "@/api/user";
  import { getCurrentInstance } from "@vue/runtime-core";
  import { useRouter } from "vue-router";
  import { appendJs } from "@/utils/tools.js";
  import { currentHost } from "@/utils/request.js";
  
  export default {
    setup() {
      const { t: $t } = useI18n();
      const { proxy } = getCurrentInstance();
      const store = useStore();
      const router = useRouter();
      const state = reactive({
        useEnterprise: 2,
        useUnitRegister: 2,
        useName: 0,
        useAddress: 0,
        studyCopyright: "",
        icpNo: "",
        ncNo: "",
        submitLoading: false,
        useArea: 0,
        areaTree: [],
        formSetting: [],
      });
      const formRef = ref();
      const formState = reactive({
        enterpriseId: undefined,
        realName: "",
        account: "",
        password: "",
        mobile: "",
        code: "",
        email: "",
        address: "",
        city: [],
      });
      const enterpriseList = ref([]);
      const codeText = ref($t('login.get_code'));
      // 获取验证码
      const disabled = ref(false);
      const successRegister = ref(false);
      let validateId = async (rule, value) => {
        if (!value || value == "") {
          return Promise.reject($t('login.enterprise_p'));
          // 请选择单位
        }
        return Promise.resolve();
      };
      const rules = {
        enterpriseId: [
          {
            required: true,
            validator: validateId,
            trigger: "change",
          },
        ],
        realName: [
          {
            required: true,
            message: $t('login.name_p'),
            // 请输入姓名
            trigger: "change",
          },
        ],
        account: [
          {
            required: true,
            message: $t('login.account_regist'),
            // 请输入至少三个字符
            trigger: "change",
          },
          {
            validator: validatorAccount,
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: $t('login.password_p'),
            // 请输入密码
            trigger: "change",
          },
          {
            validator: validatorPassword,
            trigger: "change",
          },
        ],
        mobile: [
          {
            required: true,
            message: $t('login.请输入手机号'),
            // 请输入手机号
            trigger: "change",
          },
          {
            validator: validatorMobile,
            trigger: "change",
          },
        ],
        code: [
          {
            required: true,
            message: $t('login.code_p'),
            // 请输入验证码
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: $t('login.email_p'),
            // 请输入邮箱
            trigger: "change",
          },
          {
            validator: validatorEmail,
            trigger: "change",
          },
        ],
        city: [
          {
            required: true,
            message: $t('login.area_p'),
            // 请选择区县
            trigger: "change",
            type: "array",
          },
        ],
      };
  
      watch(
        () => store.getters.platformConfig,
        (val) => {
          state.useEnterprise = val.useEnterprise;
          state.useUnitRegister = val.useUnitRegister;
          state.useName = val.useName;
          state.useAddress = val.useAddress;
          state.studyCopyright = val.studyCopyright;
          state.icpNo = val.icpNo;
          state.ncNo = val.ncNo;
          appendJs("Track", val.studyTrack);
          state.useArea = val.useArea;
        },
        { immediate: true, deep: true }
      );
  
      const getEnterprise = () => {
        searchEnterprise({
          keyword: "",
          site: currentHost,
        }).then((res) => {
          if (res.ret == 0) {
            res.data.map((item) => {
              enterpriseList.value.push({
                value: item.id,
                label: item.name,
              });
            });
          }
        });
      };
      getEnterprise();
      const filterOption = (input, option) => {
        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      };
  
      const getCode = (searchValue) => {
        if (searchValue == "" || !/^1[3456789]\d{9}$/.test(searchValue)) {
          proxy.$message.error($t('login.mobile_err'));
          // 手机号不正确
          return false;
        }
        if(disabled.value ){
          proxy.$message.error($t('请等待倒计时'));
          // 手机号不正确
          return false;
        }
        disabled.value = true;
        codeText.value = 60;
        let timer = setInterval(() => {
          codeText.value = parseInt(codeText.value) - 1;
          if (codeText.value == 0) {
            disabled.value = false;
            codeText.value = $t('login.get_code');
            // 获取验证码
            clearInterval(timer);
          }
        }, 1000);
        sendCode({
          mobile: searchValue,
          site: currentHost,
        }).then((res) => {
          if (res.ret == 0) {
            proxy.$message.success(res.msg); //发送验证码成功，请查收！
          }
        });
      };
  
      // 区县
      const initArea = () => {
        if (!state.useArea) return false;
        getArea().then((res) => {
          if (res.ret === 0) {
            const data = res.data || [];
            if (state.useArea === 1) {
              state.areaTree = data;
            } else {
              findCodeChrildren(data, state.useArea);
            }
          }
        });
      };
      const findCodeChrildren = (data, code) => {
        if (data && data.length) {
          for (let index = 0; index < data.length; index++) {
            const item = data[index];
            if (item.code == code) {
              state.areaTree = item.list;
            } else {
              findCodeChrildren(item.list, code);
            }
          }
        }
      };
      initArea();
  
      const onSubmit = () => {
        formRef.value
          .validate()
          .then(() => {
            state.submitLoading = true;
            registerIndividual({
              enterpriseId: formState.enterpriseId,
              realName: formState.realName
                ? formState.realName
                : formState.account,
              account: formState.account,
              password: proxy.$getRsaCode(formState.password),
              mobile: formState.mobile,
              address: formState.address,
              code: formState.code,
              email: formState.email,
              site: currentHost,
              // platform: 1,
              city: formState.city.join(" "),
            }).then((res) => {
              state.submitLoading = false;
              if (res.ret == 0) {
                successRegister.value = true;
              }
            });
          })
          .catch((error) => {
            console.log("error", error);
          });
      };
  
      const toLogin = () => {
        router.push({ path: `/login` });
      };
  
      return {
        newWindow: store.getters.companyInfo.useNewWindow,
        ...toRefs(state),
        formRef,
        formState,
        codeText,
        disabled,
        rules,
        getCode,
        onSubmit,
        enterpriseList,
        filterOption,
        successRegister,
        toLogin,
      };
    },
  };
  </script>
  
  <style lang="less" scoped>
  @import "../../assets/style/register.less";
  .copyright {
    width: 960px;
    margin: 0 auto;
    padding: 20px 0;
    text-align: center;
    color: #899299;
    ::v-deep(a) {
      color: #899299 !important;
    }
  }
  </style>
  